var calltracksReplace = (function () {
  var self = {};
  var calltracksData, eventTracking;

  var telSpan = function () {
    let elem = document.createElement('span')
    elem.className = 'calltracks_' + calltracksData.placeholder;
    elem.innerHTML = calltracksData.phone;
    return elem;
  }

  var telLink = function () {
    let elem = document.createElement('a');
    elem.appendChild(telSpan());
    elem.className = 'js--ga-event';
    elem.href = 'tel:' + telPhoneFormatted();
    if (eventTracking !== undefined) {
      elem = defineEventTracking(elem)
    }
    return elem;
  }

  var telPhoneFormatted = function () {
    return calltracksData.phone.replace(/\s/g, '');
  }

  var defineEventTracking = function (elem) {
    // https://jsperf.com/html5-dataset-vs-native-setattribute
    elem.setAttribute('data-event-category', eventTracking.category);
    elem.setAttribute('data-event-action', eventTracking.action);
    elem.setAttribute('data-event-label', eventTracking.label);
    return elem;
  }

  var replace = function () {
    var elements = document.querySelectorAll(calltracksData.selector);
    for (let i = 0, n = elements.length; i < n; i++) {
      elements[i].replaceWith(telLink());
    };
    refreshCalltracks();
  }

  var refreshCalltracks = function () {
    if (typeof __ctwnd == 'object') {
      __ctwnd.reinit();
    } else {
      console.info('Calltracks not loaded');
    }
  }

  self.init = function (init) {
    calltracksData = init.calltracks;
    eventTracking = init.events;

    if (calltracksData !== undefined) {
      replace();
    } else {
      console.error('Calltracks data must be defined!');
    }
  }

  return self;
}());

var initializer;
if (typeof Turbolinks == 'undefined') {
  initializer = 'DOMContentLoaded'
} else {
  initializer = 'turbolinks:load';
}

document.addEventListener(
  initializer,
  calltracksReplace.init(
    {
      calltracks: {
        // phone: '0123456',
        phone: document.querySelector('script[data-calltracks-phone]').getAttribute('data-calltracks-phone'),
        placeholder: 'calltracks_bannerjonescouk-main',
        selector: '.calltracks-span',
      },
      events: {
        category: 'Contact',
        action: 'click',
        label: 'Offline phone'
      }
    }
  )
);
